import { Link } from "gatsby";
import React from "react";

import GameonLogoColor from "../../../../static/svg/gameon-logo-color.svg";
import LayoutSlack from "../../../components/layout-slack/layout-slack";
import Seo from "../../../components/seo/seo";

import "./success.scss";

const SuccessPage = () => (
  <div className="slack-success-page white-header-bg">
    <LayoutSlack>
      <Link to="/">
        <GameonLogoColor />
      </Link>
      <div className="success-msg">
        <h1 className="success-heading">Success!</h1>
        <p className="success-text">
          Head to your Slack Workspace and the Apps section to start chatting!
        </p>
      </div>
    </LayoutSlack>
  </div>
);

export const Head = ({ location }) => {
  const seoMeta = {
    metaTitle: "Success!",
    locale: "en-US",
    slug: location.pathname,
  };
  return <Seo {...seoMeta} />;
};

export default SuccessPage;
